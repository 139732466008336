ol { padding: 0px; margin: 0px; list-style: none; color: #ccc;  }
li.liplayer{ list-style-type: none !important; position: relative; cursor: pointer; display:block }
li.playing { list-style-type: none; color: #aaa; text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.3); }
li.playing a { color: #000; }

.audiojs {margin: 0 auto !important;}
.audiojs .play-pause { width:35px !important; }
.panel-default.playing { border-color: #DBF; }

#shortcuts { z-index:9999; position: fixed; bottom: 0px; width: 100%; color: #666; font-size: 0.9em; margin: 60px 0px 0px; padding: 10px 0px; background: #f3f3f3; background: rgba(240, 240, 240, 0.7); }
#wrapper { width:350px;position: fixed; margin: 40px auto; bottom:5px; z-index:999 }