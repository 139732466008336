body {
    font-family: 'Lato';
}

.fa-btn {
    margin-right: 6px;
}

.flash {
    background:#F6624A;
    color:white;
    width:200px;
    position:absolute;
    right: 20px;
    bottom: 20px;
    padding: 1em;
    display: none;
}
iframe {
    border: 0px !important;
}

.mixblock {
    margin-bottom:20px;
}

.player {
    width:100%;
    background-color: #0097d5;
}

.maxwide {
    width:100%
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;
}
